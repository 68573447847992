let R6 = {
    name: 'R6',
    vmaxpo: '1.8',
    vmaxori: '400',
    amaxpo: '6',
    amaxori: '900',
    jointAmount: 6,
    joints: [
        {
            theta: '0',
            d: '0.4',
            a: '0',
            alpha: '90',
            qmin: '-180',
            qmax: '180',
            qdmax: '180',
            qddmax: '460',
            h: 1
        },
        {
            theta: '90',
            d: '0',
            a: '0.6',
            alpha: '0',
            qmin: '-45',
            qmax: '225',
            qdmax: '180',
            qddmax: '460',
            h: 1
        },
        {
            theta: '0',
            d: '0',
            a: '0',
            alpha: '90',
            qmin: '-45',
            qmax: '225',
            qdmax: '180',
            qddmax: '460',
            h: 1
        },
        {
            theta: '0',
            d: '0.6',
            a: '0',
            alpha: '-90',
            qmin: '-180',
            qmax: '180',
            qdmax: '400',
            qddmax: '1200',
            h: 1
        },
        {
            theta: '0',
            d: '0',
            a: '0',
            alpha: '90',
            qmin: '-135',
            qmax: '135',
            qdmax: '400',
            qddmax: '1200',
            h: 1
        },
        {
            theta: '0',
            d: '0.25',
            a: '0',
            alpha: '0',
            qmin: '-180',
            qmax: '180',
            qdmax: '700',
            qddmax: '1600',
            h: 1
        }
    ],
    images: {
        preview: 'R6-preview.png',
        detail: 'R6-detail.jpg'
    },
    preDefinedMask: [true, true, true, true, true, true]
};

export default R6;