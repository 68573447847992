import React, {Component} from 'react';
import {ListGroupItem, Glyphicon} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store.js';

class Command extends Component {
    removeCommand(e) {
        store.commands.splice(parseInt(e.target.getAttribute('index'), 10), 1);
        store.refreshCommandsIndex();
        store.commandValueChanged();
    }

    render() {
        let command = this.props.command;
        return (
            <ListGroupItem index={command.index} commandid={command.id} onClickCapture={this.props.onClickHandler}
                           className={parseInt(store.selectedCommand, 10) === command.id ? 'active' : ''}>
                <span className="pull-right" isremovebutton="true" onClick={this.removeCommand} index={command.index}>
                    <Glyphicon glyph="trash" isremovebutton="true" index={command.index}/>
                </span>
                <span className="sort-handle m-r">
                    <Glyphicon glyph="menu-hamburger"/>
                </span>
                <span className="command-title" commandid={command.id}>
                    {command.type && store.robot.data ? (
                        command.type + ' ' + (command.data ? command.data.toString(store.robot.data.jointAmount) : '')
                    ) : <i commandid={command.id}>nicht definiert</i>}
                </span>
            </ListGroupItem>
        )
    }
}

export default easyComp(Command);
