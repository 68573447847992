import Fkin from "./Fkin";
import DiffCartesian from "./DiffCartesian";
import Jacobimatrix from "./Jacobimatrix";

let math = require('mathjs');

class IkpJTrans {
    run(T_ziel, q0, K_trans, r, N, cnt_max, abbr_cart_min, qmin, qmax) {
        let fkin = new Fkin();
        let diffCartesian = new DiffCartesian();
        let jacobimatrix = new Jacobimatrix();
        let abbr_cart = 100;
        let abbr_q = 100;
        let cnt = 0;
        let px = [];
        let py = [];
        let richt_dq = math.zeros(N);
        let efgzw = math.ones(N, 3);
        let abbr_cartzw = math.ones(N);
        let qzw = math.ones(N, 3);

        let q = math.squeeze(q0);
        let T, T0, TW, e, e_trans, J, J_transp, dq, richt_dq_neu;

        //console.log('K_trans', K_trans);

        while ((abbr_cart > abbr_cart_min) && (cnt < cnt_max)) {
            [T, T0] = fkin.run(r, q);

            TW = math.subset(T0, math.index([...Array(T0.size()[0]).keys()], [...Array(T0.size()[1]).keys()], N - 1));
            TW = math.squeeze(TW);

            e = diffCartesian.run(math.squeeze(TW), math.squeeze(T_ziel));
            e_trans = math.transpose(e);

            abbr_cart = math.norm(e);

            //console.log('TW', TW, 'T_ziel', T_ziel, 'abbr_cart', abbr_cart);

            J = jacobimatrix.run(r, T0);
            J_transp = math.transpose(J);

            dq = math.multiply(math.multiply(J_transp, K_trans), e);
            abbr_q = math.norm(dq);

            //console.log('ikpjtrans-1', q, dq);
            q = math.add(q, dq);

            cnt = cnt + 1;

            if (cnt < (cnt_max - 50)) {
                richt_dq_neu = math.divide(dq, math.norm(dq));
                richt_dq = math.add(math.multiply(0.8, richt_dq), math.multiply(0.2, richt_dq_neu));
            }
        }
        return [q, cnt, abbr_cart];
    }
}

export default IkpJTrans;