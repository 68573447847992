import React, {Component} from 'react';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import {
    Modal,
    FormControl,
    Button,
    Alert,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    InputGroup,
    DropdownButton,
    MenuItem,
    Table
} from 'react-bootstrap';
import store from '../../store.js'

import RobotFormJoin from '../elements/RobotFormJoint';

class RobotForm extends Component {

    store = {
        robotData: {
            name: '',
            vmaxpo: '',
            vmaxori: '',
            amaxpo: '',
            amaxori: '',
            jointAmount: 5,
            joints: []
        },
        robotDataErrors: []
    };

    textFieldChange(e) {
        this.store.robotData[e.target.name] = e.target.value;
    }

    numberFieldChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.store.robotData[e.target.name] = e.target.value;
        }
    }

    jointAmountChange(val) {
        this.store.robotData.jointAmount = val;
        if (val < this.store.robotData.joints.length) {
            this.store.robotData.joints.splice(val);
        }
        if (val > this.store.robotData.joints.length) {
            let amount = this.store.robotData.jointAmount - this.store.robotData.joints.length
            for (let i = 0; i < amount; i++) {
                this.store.robotData.joints.push({
                    theta: '',
                    d: '',
                    a: '',
                    alpha: '',
                    qmin: '',
                    qmax: '',
                    qdmax: '',
                    qddmax: '',
                    h: 0
                })
            }
        }
    }

    jointNumberFieldChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.store.robotData.joints[e.target.getAttribute('jointkey')][e.target.name] = e.target.value;
        }
    }

    jointHChange(val, e) {
        this.store.robotData.joints[e.target.getAttribute('jointkey')].h = val;
    }

    saveRobot() {
        this.store.robotDataErrors = [];
        if (this.validateRobotData()) {
            store.robot.data = {...this.store.robotData};
            store.robot.isSet = true;
            this.props.closeModalAfterSaving();
        }
    }

    validateRobotData() {
        let hasErrors = false;

        if (this.store.robotData.name.length < 1) {
            this.store.robotDataErrors.push('Bitte geben Sie eine Bezeichnung für den Roboter an.');
            hasErrors = true;
        }

        return !hasErrors;
    }

    componentWillMount() {
        if (store.robot.isSet) {
            this.store.robotData = {...store.robot.data};
        } else {
            for (let i = 0; i < this.store.robotData.jointAmount; i++) {
                this.store.robotData.joints.push({
                    theta: '',
                    d: '',
                    a: '',
                    alpha: '',
                    qmin: '',
                    qmax: '',
                    qdmax: '',
                    qddmax: '',
                    h: 0
                })
            }
        }
    }

    render() {

        const {robotData} = this.store;
        const {jointNumberFieldChange, jointHChange} = this;
        const {readonly} = this.props;

        return (
            <div className="form-robotForm">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormControl
                            type="text"
                            value={robotData.name}
                            placeholder="Bezeichnung"
                            onChange={this.textFieldChange}
                            name="name"
                            className="input-name"
                            readOnly={readonly}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.store.robotDataErrors.length > 0 ?
                        <Alert bsStyle="warning">
                            {this.store.robotDataErrors.map(function (message, i) {
                                return <div key={i}>{message}</div>
                            })}
                        </Alert>
                        : null}

                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>
                                    vmax, pos
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.vmaxpo}
                                        onChange={this.numberFieldChange}
                                        name="vmaxpo"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Addon>m/s</InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>
                                    vmax, ori
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.vmaxori}
                                        onChange={this.numberFieldChange}
                                        name="vmaxori"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Addon>deg/s</InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>
                                    amax, pos
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.amaxpo}
                                        onChange={this.numberFieldChange}
                                        name="amaxpo"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Addon>m/s<sup>2</sup></InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>
                                    amax, ori
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.amaxori}
                                        onChange={this.numberFieldChange}
                                        name="amaxori"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Addon>deg/s<sup>2</sup></InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col xs={6}>
                            {!readonly ? (
                                <DropdownButton
                                    title={robotData.jointAmount + ' Gelenk' + (robotData.jointAmount > 1 ? 'e' : '')}
                                    onSelect={this.jointAmountChange}
                                    id="jointAmount"
                                >
                                    {[...Array(10).keys()].map(function (x) {
                                        let i = x + 1;
                                        return <MenuItem key={i} active={i === robotData.jointAmount} eventKey={i}>{i} Gelenk{i > 1 ? 'e' : null}</MenuItem>
                                    })}
                                </DropdownButton>
                            ) : (
                                <h3>
                                    Gelenke
                                </h3>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Table>
                                <thead>
                                <tr>
                                    <th/>
                                    <th>
                                        theta
                                    </th>
                                    <th>
                                        d
                                    </th>
                                    <th>
                                        a
                                    </th>
                                    <th>
                                        alpha
                                    </th>
                                    <th>
                                        qmin
                                    </th>
                                    <th>
                                        qmax
                                    </th>
                                    <th>
                                        qdmax
                                    </th>
                                    <th>
                                        qddmax
                                    </th>
                                    <th>
                                        joint
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {[...Array(robotData.jointAmount).keys()].map(function (x) {
                                    return <RobotFormJoin key={x} i={x} robotData={robotData}
                                                          jointNumberFieldChange={jointNumberFieldChange}
                                                          jointHChange={jointHChange} readonly={readonly}/>
                                })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.closeModal}>Abbrechen</Button>
                    <Button onClick={this.saveRobot} bsStyle="primary">Speichern</Button>
                </Modal.Footer>
            </div>
        )
    }
}

export default easyComp(RobotForm);