class CommandFormAccelCpModel {

    values = (new Array(2)).fill('100');

    toString() {
        let out = '( ';
        for (let i = 0; i < 2; i++) {
            out += this.values[i] + ' ';
        }
        out += ')';
        return out
    }
}

export default CommandFormAccelCpModel;