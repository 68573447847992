import Ramp from './Ramp';
import Sino from './Sino';


class Synchronize {

    run(command, se, vm, bm, tIpo, robot) {

        let s = [];

        let N;
        let i;
        let EPS = [];
        let te = [];
        let tb = [];
        let tv = [];
        let temax;
        let iLeit;

        let rampt = [];
        let sinot = [];

        let ramp = new Ramp();
        let sino = new Sino();

        if (command.type === 'PTP' || command.type === 'PTPREL') {
            N = robot.jointAmount;
            for (i = 0; i < N; i++) {
                EPS[i] = 0.5e-4;
            }
        } else {
            N = 2;
            EPS = [1e-5, 1e-5];
        }

        // RAMP
        if (parseInt(command.data.velocity, 10) === 1) {
            // Berechnung der Bahndauer fuer alle Bewegungen
            for (i = 0; i < N; i++) {
                if (Math.abs(se[i]) < EPS[i]) {
                    te[i] = 0;
                    tb[i] = 0;
                    tv[i] = 0;
                } else {
                    if (vm[i] < Math.sqrt(se[i] * bm[i])) {
                        tb[i] = (Math.floor(vm[i] / bm[i] / tIpo) + 1) * tIpo;
                        tv[i] = (Math.floor(se[i] / vm[i] / tIpo) + 1) * tIpo;
                    } else {
                        vm[i] = Math.sqrt(se[i] * bm[i]);
                        tb[i] = (Math.floor(se[i] / vm[i] / tIpo) + 1) * tIpo;
                        vm[i] = se[i] / tb[i];
                        tv[i] = tb[i];
                    }
                    te[i] = tv[i] + tb[i];
                }
            }

            // Bestimmung der maximalen Bahndauer (Leitbewegung)
            temax = Math.max(...te);
            iLeit = te.indexOf(temax);

            // Angleichung der Geschwindigkeiten und Beschleunigungen
            if (temax === 0) {
                s[0] = [];
                for (i = 0; i < N; i++) {
                    s[0][i] = 1;
                }
            } else {
                for (i = 0; i < N; i++) {

                    tb[i] = tb[iLeit];
                    tv[i] = tv[iLeit];
                    te[i] = te[iLeit];
                    vm[i] = se[i]/tv[i];
                    bm[i] = vm[i]/tb[i];

                    rampt = ramp.run(tb[i], tv[i], temax, vm[i], bm[i], se[i], tIpo);
                    for (let j = 0; j < rampt.length; j++) {
                        if (s[j] === undefined) {
                            s[j] = [];
                        }
                        s[j][i] = rampt[j];
                    }
                }
            }
        }
        // SINO
        else if (parseInt(command.data.velocity, 10) === 2) {
            // Berechnung der Bahndauer für alle Bewegungen
            for (i = 0; i < N; i++) {
                if (Math.abs(se[i]) < EPS[i]) {
                    te[i] = 0;
                    tb[i] = 0;
                    tv[i] = 0;
                } else {
                    if (vm[i] < Math.sqrt(se[i] * bm[i] / 2)) {
                        tb[i] = (Math.floor(2 * vm[i] / bm[i] / tIpo) + 1) * tIpo;
                        tv[i] = (Math.floor(se[i] / vm[i] / tIpo) + 1) * tIpo;
                    } else {
                        vm[i] = Math.sqrt(se[i] * bm[i] / 2);
                        tb[i] = (Math.floor(se[i] / vm[i] / tIpo) + 1) * tIpo;
                        tv[i] = tb[i];
                        vm[i] = se[i] / tv[i];
                    }
                    te[i] = tv[i] + tb[i];
                }
            }

            // Bestimmung der maximalen Bahndauer (Leitbewegung)
            temax = Math.max(...te);
            iLeit = te.indexOf(temax);

            // Angleichung der Geschwindigkeiten und Beschleunigungen
            if (temax === 0) {
                s[0] = [];
                for (i = 0; i < N; i++) {
                    s[0][i] = 1;
                }
            } else {

                for (i = 0; i < N; i++) {

                    tb[i] = tb[iLeit];
                    tv[i] = tv[iLeit];
                    te[i] = te[iLeit];
                    vm[i] = se[i] / tv[i];
                    bm[i] = 2 * vm[i] / tb[i];

                    sinot = sino.run(tb[i], tv[i], temax, vm[i], bm[i], se[i], tIpo);
                    for (let j = 0; j < sinot.length; j++) {
                        if (s[j] === undefined) {
                            s[j] = [];
                        }
                        s[j][i] = sinot[j];
                    }
                }
            }
        }

        return [s, te];
    }
}

export default Synchronize;
