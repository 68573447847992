import store from '../../store';
import Fkin from './Fkin';
import Rotmat2quat from './Rotmat2quat';
import Eul2rotmat from "./Eul2rotmat";
import Rotmat2eul from "./Rotmat2eul";
import Eul2quat from "./Eul2quat";
import Synchronize from "./Synchronize";
import Quat2rotmat from "./Quat2rotmat";
import Ikin from "./Ikin";
import IkinCart from "./IkinCart";
import IkinCartR6 from "./IkinCartR6";

let math = require('mathjs');

class Lin {

    run(command, qstart) {
        let robot = store.translation.input.robot;
        let N = robot.jointAmount;
        let joints = robot.joints;
        let Q = [];
        let q2;

        let fkin = new Fkin();
        let rotmat2quat = new Rotmat2quat();
        let eul2rotmat = new Eul2rotmat();
        let rotmat2eul = new Rotmat2eul();
        let eul2quat = new Eul2quat();
        let synchronize = new Synchronize();
        let quat2rotmat = new Quat2rotmat();
        let ikin = new Ikin();
        let ikinCart = new IkinCart();

        // Startstellung (xyz und Quaternion)
        let T_T0 = fkin.run({...robot}, qstart);
        let T0 = T_T0[1];
        let xyz = T0.subset(math.index([0, 1, 2], 3, N - 1)).resize([3]);
        let q1 = rotmat2quat.run(math.subset(T0, math.index([0, 1, 2], [0, 1, 2], N - 1)));

        let pose = command.data.cartesianTargetPose;

        /*console.log('----- lin start -----');
        console.log('qstart', qstart);
        console.log('vm', [store.translation.input.vmaxpo / 2, store.translation.input.vmaxori / 2]);
        console.log('bm', [store.translation.input.amaxpo / 2, store.translation.input.amaxori / 2]);
        console.log('tIpo', store.translation.input.tIpo);
        console.log('T0', T0);
        console.log('xyz', xyz);
        console.log('----- lin end -----');*/

        // Zielstellung ermitteln
        // cartesian
        if (parseInt(command.data.target, 10) === 1) {
            if (command.type === 'LINREL') {
                pose = math.subset(pose, math.index([0, 1, 2]), math.add(xyz, math.subset(pose, math.index([0, 1, 2]))));
                let AW = eul2rotmat.run(math.subset(pose, math.index([3, 4, 5])));
                let AW0 = math.multiply(T0.subset(math.index([0, 1, 2], [0, 1, 2], N - 1)).resize([3, 3]), AW);
                pose = math.subset(pose, math.index([3, 4, 5]), math.squeeze(math.multiply(rotmat2eul.run(AW0), 180 / Math.PI)));
            }
            q2 = eul2quat.run(math.multiply(math.subset(pose, math.index([3, 4, 5])), Math.PI / 180));
        }
        // joint
        else {
            if (command.type === 'LINREL') {
                pose = math.add(pose, qstart);
            }
            T_T0 = fkin.run({...robot}, pose);
            T0 = T_T0[1];
            pose = math.subset(pose, math.index([0, 1, 2], 0), math.subset(T0, math.index([0, 1, 2], 3, N)));
            q2 = rotmat2quat.run(math.subset(T0, math.index([0, 1, 2], [0, 1, 2], N)));
        }

        // Weglaenge
        let se = [];
        se[0] = math.norm(math.subtract(math.subset(pose, math.index([0, 1, 2])), math.subset(xyz, math.index([0, 1, 2]))));
        se[1] = math.acos(math.add(math.multiply(math.subset(q1, math.index(0, 0)), math.subset(q2, math.index(0))), math.dot(math.squeeze(math.subset(q1, math.index([1, 2, 3], 0))), math.subset(q2, math.index([1, 2, 3])))));

        let s_te = synchronize.run({...command}, se, [store.translation.input.vpo, store.translation.input.vori], [store.translation.input.apo, store.translation.input.aori], store.translation.input.tIpo, robot);
        let s = s_te[0];
        let te = s_te[1];

        /*console.log('s', s);
        console.log('te', te);*/

        // Interpolation
        let F = math.zeros(3, 4, s.length);
        for(let i = 0; i < s.length; i++) {
            // Lage
            if(se[0] === 0) {
                F = math.subset(F, math.index([0, 1, 2], 3, i), math.subset(pose, math.index([0, 1, 2])));
            } else {
                F = math.subset(F, math.index([0, 1, 2], 3, i), math.add(math.subset(xyz, math.index([0, 1, 2])), math.multiply(s[i][0], math.subtract(math.subset(pose, math.index([0, 1, 2])), math.subset(xyz, math.index([0, 1, 2]))))));
            }

            // Orientierung
            if(se[1] === 0) {
                F = math.subset(F, math.index([0, 1, 2], [0, 1, 2], i), quat2rotmat.run(q2));
            } else {
                let q = math.add(math.squeeze(math.multiply(math.divide(math.sin(math.multiply((1 - s[i][1]), se[1])), math.sin(se[1])), q1)), math.multiply(math.divide(math.sin(math.multiply(s[i][1], se[1])), math.sin(se[1])), q2));
                F = math.subset(F, math.index([0, 1, 2], [0, 1, 2], i), quat2rotmat.run(q));
            }
        }

        if(robot.name === 'R6') {
            Q = (new IkinCartR6()).run(F, qstart, robot, command.data.mask.map((el, i) => {
                if(i > 2) {
                    return !el;
                } else {
                    return el;
                }
            }), store.translation.input.tIpo);
        } else {
            Q = ikinCart.run(F, qstart, robot, command.data.mask.map((el, i) => {
                if(i > 2) {
                    return !el;
                } else {
                    return el;
                }
            }), store.translation.input.tIpo);
        }

        return Q;
    }
}

export default Lin;
