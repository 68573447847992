let math = require('mathjs');

class Fkin {
    run(robot, q_akt) {
        //console.log('q_akt', q_akt);
        let N = robot.jointAmount;
        let T = math.zeros(4, 4, N);
        let T0 = math.zeros(4, 4, N);

        let i;

        let h_gel = [];
        let theta = [];
        let alpha = [];
        let a = [];
        let d = [];
        let joint;
        for (i = 0; i < N; i++) {
            joint = robot.joints[i];
            h_gel.push(joint.h);
            theta.push(joint.theta);
            alpha.push(joint.alpha);
            a.push(joint.a);
            d.push(joint.d);
        }

        q_akt = math.squeeze(q_akt);
        for (i = 0; i < N; i++) {
            if (h_gel[i] === 1) {
                theta[i] = math.subset(q_akt, math.index(i));
            } else {
                d[i] = math.subset(q_akt, math.index(i));
            }

            T.subset(math.index(0, [0, 1, 2, 3], i), [Math.cos(theta[i]), -1 * Math.sin(theta[i]) * Math.cos(alpha[i]), Math.sin(theta[i]) * Math.sin(alpha[i]), a[i] * Math.cos(theta[i])]);
            T.subset(math.index(1, [0, 1, 2, 3], i), [Math.sin(theta[i]), Math.cos(theta[i]) * Math.cos(alpha[i]), -1 * Math.cos(theta[i]) * Math.sin(alpha[i]), a[i] * Math.sin(theta[i])]);
            T.subset(math.index(2, [0, 1, 2, 3], i), [0, Math.sin(alpha[i]), Math.cos(alpha[i]), d[i]]);
            T.subset(math.index(3, [0, 1, 2, 3], i), [0, 0, 0, 1]);
        }

        T0.subset(math.index([0, 1, 2, 3], [0, 1, 2, 3], 0), T.subset(math.index([0, 1, 2, 3], [0, 1, 2, 3], 0)));
        for (i = 1; i < N; i++) {
            T0.subset(math.index([0, 1, 2, 3], [0, 1, 2, 3], i), math.multiply(T0.subset(math.index([0, 1, 2, 3], [0, 1, 2, 3], i - 1)).resize([4, 4]), T.subset(math.index([0, 1, 2, 3], [0, 1, 2, 3], i)).resize([4, 4])));
        }

        return [T, T0];

    }
}

export default Fkin;