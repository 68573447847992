import React, {Component} from 'react';
import {Row, Col, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';

class CommandGripper extends Component {

    render() {
        let command = this.props.command;

        return (
            <div>
                <i>no settings available for this command</i>
            </div>
        )
    }
}

export default easyComp(CommandGripper);
