let math = require('mathjs');

class Jacobimatrix {
    run(rob, T0) {
        let i;

        let p_dist;

        let N = rob.jointAmount;
        let h = [];
        for (i = 0; i < N; i++) {
            h[i] = rob.joints[i].h;
        }
        let J = math.zeros(6, N);
        let z_achse = math.matrix([[0], [0], [1]]);

        let p0TCO = T0.subset(math.index([0, 1, 2], 3, N - 1));

        let null_vek = math.zeros(3, 1);

        if (h[0] === 1) {
            p_dist = T0.subset(math.index([0, 1, 2], 3, N - 1));
            J.subset(math.index([0, 1, 2], 0), math.cross(math.squeeze(z_achse), math.squeeze(p_dist)));
            J.subset(math.index([3, 4, 5], 0), z_achse);
        } else {
            J.subset(math.index([0, 1, 2], 0), z_achse);
            J.subset(math.index([3, 4, 5], 0), null_vek);
        }

        for (i = 1; i < N; i++) {
            if (h[i] === 1) {
                z_achse = T0.subset(math.index([0, 1, 2], 2, i - 1));
                p_dist = math.subtract(p0TCO, T0.subset(math.index([0, 1, 2], 3, i - 1)));
                z_achse = math.squeeze(z_achse);
                p_dist = math.squeeze(p_dist);
                J.subset(math.index([0, 1, 2], i), math.cross(z_achse, p_dist));
                J.subset(math.index([3, 4, 5], i), z_achse);
            } else {
                z_achse = T0.subset(math.index([0, 1, 2], 2, i - 1));
                z_achse = math.squeeze(z_achse);
                J.subset(math.index([0, 1, 2], i), z_achse);
                J.subset(math.index([3, 4, 5], i), null_vek);
            }
        }

        return J;
    }
}

export default Jacobimatrix;