import store from '../store';
import Ptp from './translation/Ptp';
import Lin from './translation/Lin';
import Circ from './translation/Circ';
import PrepareCommandService from './translation/PrepareCommandService';
import PrepareRobotService from './translation/PrepareRobotService';

class TranslationService {

    run = function () {
        //synchronize.run();
        if (this.init()) {
            let commands = store.translation.input.commands;
            let command;
            let result;
            let ptp = new Ptp();
            let lin = new Lin();
            let circ = new Circ();

            store.translation.currentAction = 'translating';

            for (let i = 0; i < commands.length; i++) {
                command = {...commands[i]};
                if (command.type === 'PTP' || command.type === 'PTPREL') {
                    result = ptp.run(command, store.translation.output.Q[store.translation.output.Q.length - 1]);
                    if (result !== false) {
                        store.translation.output.Q = store.translation.output.Q.concat(result);
                    }
                }
                else if (command.type === 'SPEEDPTP') {
                    for (let j = 0; j < store.translation.input.robot.jointAmount; j++) {
                        store.translation.input.vptp[j] = store.translation.input.qdmax[j] * parseInt(command.data.jointValue[j], 10) / 100;
                    }
                }
                else if (command.type === 'ACCELPTP') {
                    for (let j = 0; j < store.translation.input.robot.jointAmount; j++) {
                        store.translation.input.bptp[j] = store.translation.input.qddmax[j] * parseInt(command.data.jointValue[j], 10) / 100;
                    }
                }
                else if (command.type === 'LIN' || command.type === 'LINREL') {
                    result = lin.run(command, store.translation.output.Q[store.translation.output.Q.length - 1]);
                    if (result !== false) {
                        store.translation.output.Q = store.translation.output.Q.concat(result);
                    }
                }
                else if (command.type === 'CIRC' || command.type === 'CIRCREL') {
                    result = circ.run(command, store.translation.output.Q[store.translation.output.Q.length - 1]);
                    if (result !== false) {
                        store.translation.output.Q = store.translation.output.Q.concat(result);
                    }
                }
                else if (command.type === 'SPEEDCP') {
                    store.translation.input.vpo = store.translation.input.vmaxpo * parseInt(command.data.values[0], 10) / 100;
                    store.translation.input.vori = store.translation.input.vmaxori * parseInt(command.data.values[1], 10) / 100;
                }
                else if (command.type === 'ACCELCP') {
                    store.translation.input.apo = store.translation.input.amaxpo * parseInt(command.data.values[0], 10) / 100;
                    store.translation.input.aori = store.translation.input.amaxori * parseInt(command.data.values[1], 10) / 100;
                }
                else if (command.type === 'HOME') {
                    let jointTargetPose = [];
                    let robot = store.translation.input.robot;
                    for (let j = 0; j < robot.joints.length; j++) {
                        if (robot.joints[j].h === 1) {
                            jointTargetPose[j] = robot.joints[j].theta / Math.PI * 180;
                        } else {
                            jointTargetPose[j] = robot.joints[j].d;
                        }
                    }
                    result = ptp.run({
                        type: 'PTP',
                        data: {
                            target: "2",
                            velocity: '2',
                            jointTargetPose
                        }
                    }, store.translation.output.Q[store.translation.output.Q.length - 1]);
                    if (result !== false) {
                        store.translation.output.Q = store.translation.output.Q.concat(result);
                    }
                }
                else if (command.type === 'WAIT') {
                    let lastVal = store.translation.output.Q[store.translation.output.Q.length - 1];
                    if (lastVal !== false) {
                        for (let j = 0; j < command.data.wait / store.translation.input.tIpo; j++) {
                            store.translation.output.Q = store.translation.output.Q.concat([lastVal]);
                        }
                    }
                }
            }
            //console.log('output', store.translation.output.Q);
            store.translation.currentAction = 'done';
            store.translation.hasResult = true;
            clearInterval(store.translation.timePassedInterval);
        }
    };

    init = function () {

        let prepareCommandService = new PrepareCommandService();
        let prepareRobotService = new PrepareRobotService();

        let i;

        // set status variables
        store.translation.startedAt = new Date().getTime();
        store.translation.inAction = true;
        store.translation.hasResult = false;
        store.translation.output.Q = [];
        store.translation.currentAction = 'initialize';
        store.translation.status = 'Ok';
        store.translation.errorMessage = null;
        store.translation.timePassedInterval = window.setInterval(function () {
            store.translation.timePassed = new Date().getTime() - store.translation.startedAt;
        }, 80);

        // read and validate t_ipo
        if (store.translation.tIpoMs < 1 || store.translation.tIpoMs > 100) {
            store.translation.stopWithError('Please enter valid T_Ipo between 1 and 100 ms.');
            return false;
        }
        store.translation.input.tIpo = parseInt(store.translation.tIpoMs, 10) / 1000;

        // read and validate commands
        if (store.commands.length < 1) {
            store.translation.stopWithError('Please add commands to your progam.');
            return false;
        }
        let commandResult;
        store.translation.input.commands = [];
        for (i = 0; i < store.commands.length; i++) {
            commandResult = prepareCommandService.run(store.commands[i]);
            if (commandResult === false) {
                store.translation.stopWithError('Your commands contain error(s) or some is not implemented yet.');
                return false;
            } else if (commandResult.type !== 'COMMENT') {
                store.translation.input.commands.push(commandResult);
            }
        }

        // read and validate robot
        let robot = store.robot;
        if (!robot.isSet) {
            store.translation.stopWithError('Please configure robot.');
            return false;
        }
        let robotResult = prepareRobotService.run(robot.data);
        if (robotResult === false) {
            store.translation.stopWithError('Your robot contains one or more error(s).');
            return false;
        } else {
            store.translation.input.robot = robotResult;
        }

        // Generalisierte Koordinaten
        let Q0 = [];
        let joint;
        for (i = 0; i < store.translation.input.robot.jointAmount; i++) {
            joint = store.translation.input.robot.joints[i];
            if (parseInt(joint.h, 10) === 1) {
                Q0.push(joint.theta ? joint.theta : 0);
            } else {
                Q0.push(joint.d ? joint.d : 0);
            }
        }
        store.translation.output.Q.push(Q0);

        // Default-Geschwindigkeiten, -Beschleunigungen

        store.translation.input.vmaxpo = store.translation.input.robot.vmaxpo;
        store.translation.input.vmaxori = store.translation.input.robot.vmaxori;
        store.translation.input.amaxpo = store.translation.input.robot.amaxpo;
        store.translation.input.amaxori = store.translation.input.robot.amaxori;

        store.translation.input.vpo = store.translation.input.vmaxpo / 2;
        store.translation.input.vori = store.translation.input.vmaxori / 2;
        store.translation.input.apo = store.translation.input.amaxpo / 2;
        store.translation.input.aori = store.translation.input.amaxori / 2;

        store.translation.input.qdmax = [];
        store.translation.input.qddmax = [];
        store.translation.input.vptp = [];
        store.translation.input.bptp = [];
        for (i = 0; i < store.translation.input.robot.jointAmount; i++) {
            store.translation.input.qdmax[i] = store.translation.input.robot.joints[i].qdmax;
            store.translation.input.qddmax[i] = store.translation.input.robot.joints[i].qddmax;
            store.translation.input.vptp[i] = store.translation.input.qdmax[i] / 2;
            store.translation.input.bptp[i] = store.translation.input.qddmax[i] / 2;
        }


        return true;

    }
}

export default TranslationService;