class PrepareCommandService {
    run(inputCommand) {
        let outputCommand;
        let i;

        try {
            switch (inputCommand.type) {
                case 'PTP':
                case 'PTPREL':
                case 'LIN':
                case 'LINREL':
                    outputCommand = {};
                    outputCommand.type = inputCommand.type;
                    outputCommand.data = {};
                    outputCommand.data.target = inputCommand.data.target;
                    outputCommand.data.velocity = inputCommand.data.velocity;
                    if (outputCommand.data.target === 1) {
                        outputCommand.data.mask = inputCommand.data.mask;
                        outputCommand.data.cartesianTargetPose = [];
                        for (i = 0; i < inputCommand.data.cartesianTargetPose.length; i++) {
                            outputCommand.data.cartesianTargetPose.push(inputCommand.data.cartesianTargetPose[i] !== '' ? parseFloat(inputCommand.data.cartesianTargetPose[i]) : 0);
                        }
                    }
                    else if (outputCommand.data.target === 2) {
                        outputCommand.data.jointTargetPose = [];
                        for (i = 0; i < inputCommand.data.jointTargetPose.length; i++) {
                            outputCommand.data.jointTargetPose.push(parseFloat(inputCommand.data.jointTargetPose[i]));
                        }
                    }
                    break;
                case 'CIRC':
                case 'CIRCREL':
                    outputCommand = {};
                    outputCommand.type = inputCommand.type;
                    outputCommand.data = {};
                    outputCommand.data.velocity = inputCommand.data.velocity;
                    outputCommand.data.mask = inputCommand.data.mask;
                    outputCommand.data.auxiliaryPoint = [];
                    for (i = 0; i < inputCommand.data.auxiliaryPoint.length; i++) {
                        outputCommand.data.auxiliaryPoint.push(inputCommand.data.auxiliaryPoint[i] !== '' ? parseFloat(inputCommand.data.auxiliaryPoint[i]) : 0);
                    }
                    outputCommand.data.cartesianTargetPose = [];
                    for (i = 0; i < inputCommand.data.cartesianTargetPose.length; i++) {
                        outputCommand.data.cartesianTargetPose.push(inputCommand.data.cartesianTargetPose[i] !== '' ? parseFloat(inputCommand.data.cartesianTargetPose[i]) : 0);
                    }
                    break;
                case 'COMMENT':
                case 'OPENGRIPPER':
                case 'CLOSEGRIPPER':
                    outputCommand = {};
                    outputCommand.type = inputCommand.type;
                    break;
                case 'SPEEDPTP':
                case 'ACCELPTP':
                case 'SPEEDCP':
                case 'ACCELCP':
                case 'HOME':
                    outputCommand = {...inputCommand};
                    break;
                case 'WAIT':
                    outputCommand = {};
                    outputCommand.type = inputCommand.type;
                    outputCommand.data = {};
                    outputCommand.data.wait = parseFloat(inputCommand.data.wait);
                    break;
                default:
                    return false;
            }
            return outputCommand;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}

export default PrepareCommandService;
