import React, {Component} from 'react';
import {Panel, ListGroup, Row, Col, Glyphicon, Button} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store.js';
import Sortable from 'react-sortablejs-guten/Sortable';
import CommandModel from '../../model/CommandModel';
import Command from '../elements/Command';
import CommandForm from '../forms/CommandForm';

class CommandsView extends Component {

    componentDidMount() {
        this.initSortable();
    }

    componentDidUpdate() {
        this.initSortable();
    }

    initSortable() {
        let el = document.getElementById('items');
        if (el) {
            Sortable.create(el, {
                handle: ".sort-handle",
                onEnd: this.saveCommandSort
            });
        }
    }

    addCommand() {
        store.commands.push(new CommandModel());
        store.refreshCommandsIndex();
        store.commandValueChanged();
    }

    saveCommandSort(evt) {
        let commands = [];
        for (let x of Array.from(evt.from.children)) {
            commands.push(store.commands[x.getAttribute('index')]);
        }
        store.commands = commands;
        store.refreshCommandsIndex();
        store.commandValueChanged();
    }

    selectCommand(e) {
        if (e.target.getAttribute('isremovebutton') !== 'true') {
            e.stopPropagation();
            store.selectedCommand = e.target.getAttribute('commandid');
        }
    }

    render() {

        let {selectCommand} = this;

        return store.robot.isSet ? (
            <div>
                <Panel>
                    <Panel.Heading>
                        <div className="pull-right">
                            <Button onClick={this.addCommand}>
                                <Glyphicon glyph="plus"/>
                            </Button>
                        </div>
                        <Panel.Title componentClass="h3">
                            Bewegungsprogramm
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col xs={6}>
                                <ListGroup id="items">
                                    {store.commands.map(function (command, i) {
                                        return <Command key={store.commands[i].id} command={store.commands[i]}
                                                        onClickHandler={selectCommand}/>
                                    })}
                                </ListGroup>
                            </Col>
                            <Col xs={6}>
                                <CommandForm/>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        ) : (
            <div>
                <i>Bitte wählen Sie einen Roboter aus, um eine Bewegung vorzugeben.</i>
            </div>
        )
    }
}

export default easyComp(CommandsView);
