import store from "../store";

class CommandFormTargetPoseModel {
    target = 1;
    velocity = 1;

    mask = [false, false, false, false, false, false];
    cartesianTargetPose = ['', '', '', '', '', ''];
    jointTargetPose = null;

    constructor(robot, defaultTarget) {
        if(robot.data.preDefinedMask) {
            this.mask = robot.data.preDefinedMask;
        }
        if (defaultTarget) {
            this.target = defaultTarget;
            if (robot) {
                this.jointTargetPose = (new Array(robot.data.jointAmount)).fill('')
            }
        }
    }

    toString(robotJointAmount) {
        let out = '( ';
        let i = 0;
        let val = null;
        if (this.target === 1) {
            for (i = 0; i < this.cartesianTargetPose.length; i++) {
                val = this.cartesianTargetPose[i];
                out += (val !== '' ? val : '-') + ' ';
            }
        } else {
            for (i = 0; i < robotJointAmount; i++) {
                val = this.jointTargetPose[i];
                out += (val !== '' ? val : '-') + ' ';
            }
        }
        out += ') ';
        out += this.target === 1 ? 'CART ' : 'JOINT ';
        out += this.velocity === 1 ? 'RAMP' : 'SINO';
        return out;
    }
}

export default CommandFormTargetPoseModel;