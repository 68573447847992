import React, {Component} from 'react';
import {Panel, FormGroup, ControlLabel, InputGroup, FormControl, Button, Row, Col, Modal} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store.js';
import PathReferenceModal from '../modals/PathReferenceModal';
import TranslationService from '../../services/TranslationService';
import ThreeDAnimationModal from "../modals/ThreeDAnimationModal";

class TranslationView extends Component {

    store = {
        pathReferenceModalOpen: false,
        threeDAnimationModalOpen: false
    };

    runTranslation() {
        window.localStorage.store = {...store};
        window.localStorage.translation = {};
        let ts = new TranslationService();
        ts.run();
    }

    tIpoChange(e) {
        if (/^-?\d*$/.test(e.target.value)) {
            store.translation.tIpoMs = e.target.value;
        }
    }

    showPathReference() {
        this.store.pathReferenceModalOpen = true;
    }

    closePathReferenceModal() {
        this.store.pathReferenceModalOpen = false;
    }

    showThreeDAnimation() {
        this.store.threeDAnimationModalOpen = true;
    }

    closeThreeDAnimationModal() {
        this.store.threeDAnimationModalOpen = false;
    }

    render() {
        return store.robot.isSet ? (
            <div>
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h3">
                            Übersetzung
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col xs={4}>
                                <FormGroup>
                                    <ControlLabel>
                                        T_Ipo
                                    </ControlLabel>
                                    <InputGroup>
                                        <FormControl
                                            type="text"
                                            value={store.translation.tIpoMs}
                                            onChange={this.tIpoChange}
                                            name="tIpoMs"
                                        />
                                        <InputGroup.Addon>ms</InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                                <Button onClick={this.runTranslation}>
                                    Run
                                </Button>
                            </Col>
                            <Col xs={6} xsOffset={2}>
                                {store.translation.inAction ? (
                                    <div>
                                        <b>Aktuelle Übersetzung:</b> {store.translation.currentAction}<br/>
                                        <b>Übersetzungszeit:</b> {store.translation.timePassed / 1000} s<br/>
                                        <b>Status:</b> {store.translation.status}
                                        {store.translation.errorMessage ? (
                                            <div className="text-danger">
                                                <b>Fehler:</b> {store.translation.errorMessage}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                                {store.translation.hasResult ? (
                                    <div>
                                        <Button onClick={this.showPathReference}>
                                            Show path reference
                                        </Button>
                                        <Modal show={this.store.pathReferenceModalOpen} onHide={this.closePathReferenceModal} bsSize="large">
                                            <PathReferenceModal closeModal={this.closePathReferenceModal}/>
                                        </Modal>
                                        <Button onClick={this.showThreeDAnimation}>
                                            Show 3D animation
                                        </Button>
                                        <Modal show={this.store.threeDAnimationModalOpen} onHide={this.closeThreeDAnimationModal} bsSize="large">
                                            <ThreeDAnimationModal closeModal={this.closeThreeDAnimationModal}/>
                                        </Modal>
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        ) : null
    }
}

export default easyComp(TranslationView);