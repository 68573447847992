class PrepareRobotService {
    run(inputRobot) {
        let outputRobot = {};

        try {
            outputRobot.name = inputRobot.name;
            outputRobot.jointAmount = inputRobot.jointAmount;
            outputRobot.vmaxpo = parseFloat(inputRobot.vmaxpo);
            outputRobot.vmaxori = parseFloat(inputRobot.vmaxori) * Math.PI / 180;
            outputRobot.amaxpo = parseFloat(inputRobot.amaxpo);
            outputRobot.amaxori = parseFloat(inputRobot.amaxori) * Math.PI / 180;

            let inputJoint, h;
            outputRobot.joints = [];
            for(let i = 0; i < inputRobot.joints.length; i++) {
                inputJoint = inputRobot.joints[i];
                h = parseInt(inputJoint.h, 10);
                outputRobot.joints[i] = {};
                outputRobot.joints[i].theta = parseFloat(inputJoint.theta) * (h === 1 ? Math.PI / 180 : 1);
                outputRobot.joints[i].d = parseFloat(inputJoint.d);
                outputRobot.joints[i].a = parseFloat(inputJoint.a);
                outputRobot.joints[i].alpha = parseFloat(inputJoint.alpha) * (h === 1 ? Math.PI / 180 : 1);
                outputRobot.joints[i].qmin = parseFloat(inputJoint.qmin) * (h === 1 ? Math.PI / 180 : 1);
                outputRobot.joints[i].qmax = parseFloat(inputJoint.qmax) * (h === 1 ? Math.PI / 180 : 1);
                outputRobot.joints[i].qdmax = parseFloat(inputJoint.qdmax) * (h === 1 ? Math.PI / 180 : 1);
                outputRobot.joints[i].qddmax = parseFloat(inputJoint.qddmax) * (h === 1 ? Math.PI / 180 : 1);
                outputRobot.joints[i].h = h;
            }

            return outputRobot;
        } catch (e) {
            console.log(e);
            return false;
        }


    }
}
export default PrepareRobotService;