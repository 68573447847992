let SCARA = {
    name: 'SCARA',
    vmaxpo: '4',
    vmaxori: '200',
    amaxpo: '8',
    amaxori: '800',
    jointAmount: 4,
    joints: [
        {
            theta: '0',
            d: '0',
            a: '0.6',
            alpha: '0',
            qmin: '-180',
            qmax: '180',
            qdmax: '200',
            qddmax: '800',
            h: 1
        },
        {
            theta: '0',
            d: '0',
            a: '0.4',
            alpha: '0',
            qmin: '-135',
            qmax: '135',
            qdmax: '250',
            qddmax: '1000',
            h: 1
        },
        {
            theta: '0',
            d: '0.15',
            a: '0',
            alpha: '0',
            qmin: '-0.1',
            qmax: '0.4',
            qdmax: '2',
            qddmax: '10',
            h: 0
        },
        {
            theta: '0',
            d: '0',
            a: '0',
            alpha: '0',
            qmin: '-180',
            qmax: '180',
            qdmax: '400',
            qddmax: '1200',
            h: 1
        }
    ],
    images: {
        preview: 'SCARA-preview.png',
        detail: 'SCARA-detail.png'
    },
    preDefinedMask: [true, true, true, true, false, false]
};

export default SCARA;
