import * as math from 'mathjs';

import Quatmult from './Quatmult';

class Eul2quat {
    run(eul) {
        let quatmult = new Quatmult();

        eul = math.squeeze(eul);
        let qz = math.matrix([math.cos(math.subset(eul, math.index(0)) / 2), 0, 0, math.sin(math.subset(eul, math.index(0)) / 2)]);
        let qy = math.matrix([math.cos(math.subset(eul, math.index(1)) / 2), 0, math.sin(math.subset(eul, math.index(1)) / 2), 0]);
        let qx = math.matrix([math.cos(math.subset(eul, math.index(2)) / 2), math.sin(math.subset(eul, math.index(2)) / 2), 0, 0]);

        return quatmult.run(qz, quatmult.run(qy, qx));
    }
}

export default Eul2quat;
