import Startposen from "./Startposen";
import IkpJTrans from "./IkpJTrans";

let math = require('mathjs');

class IkinCart {
    run(F, q, r, mask, tIpo) {

        let startposen = new Startposen();
        let ikpJTrans = new IkpJTrans();

        // maximale Anzahl der Suchschritte
        let cnt_max = 3000;

        // Elemente der Gewichtungsmatrix K
        let v_trans = 0.6;
        let v_trans_neben = v_trans / 10;

        let nw = 3;

        let abbr_q_min = 1e-5;
        let abbr_cart_min = 5e-5;
        let abbr_cart_min_sonder = 2e-4;
        let eps_q = 0.0001;

        let q_alt = q;
        let qs = [];

        let cnt = 0;

        let N = r.jointAmount;
        let hh = r.joints.map((j) => j.h);
        let qmax = r.joints.map((j) => j.qmax);
        let qmin = r.joints.map((j) => j.qmin);

        let genau = [];
        let loes_sonder = 1;
        let richt = math.zeros(N, 1);

        let K_trans_pos = math.subtract(
            math.multiply(
                math.ones(3, 3),
                v_trans_neben
            ),
            math.multiply(
                math.identity(3, 3),
                (v_trans_neben - v_trans)
            )
        );
        let K_trans_or = math.divide(K_trans_pos, 4);
        let K_trans = math.zeros(6, 6);
        K_trans = math.subset(K_trans, math.index([0, 1, 2], [0, 1, 2]), K_trans_pos);
        K_trans = math.subset(K_trans, math.index([3, 4, 5], [3, 4, 5]), K_trans_or);

        let [qstart, lges] = startposen.run(N,nw,qmin,qmax);

        let zahlposen = math.subset(math.size(F), math.index(2));

        let anz, qloes, qloes_red, richt_dq, richt_dq_neu, F_akt, abbr_cart, abbr_q, abbr_cartzw, qz, globalsuche, out_range, qt;

        for(let i = 0; i < zahlposen -1; i++) {
            anz = 0;
            qloes = [];
            qloes_red = [];
            richt_dq = math.zeros(N, lges);
            richt_dq_neu = math.zeros(N, lges);

            F_akt = math.subset(F, math.index([0, 1, 2], [0, 1, 2, 3], i));

            abbr_cart = 100;
            abbr_q = 100;
            cnt = 0;
            abbr_cartzw = math.ones(N, 1);

            [ qz, cnt, abbr_cart ] = ikpJTrans.run( F_akt, q, K_trans,r,N,cnt_max, abbr_cart_min,qmin,qmax);

            q = qz;

            // begin "out of range"
            for(let ig = 0; ig < N; ig++) {
                if(hh[ig] === 1) {
                    q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - math.fix(math.subset(q, math.index(ig)) / (2 * Math.PI + eps_q)) * 2 * Math.PI);
                    if(math.subset(qmin, math.index(ig)) >= 0 && math.subset(qmax, math.index(ig)) > 0 && math.subset(q, math.index(ig)) < 0) {
                        q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) + 2 * Math.PI);
                    }
                    if(math.subset(qmin, math.index(ig)) < 0 && math.subset(qmax, math.index(ig)) >= 0) {
                        if(math.subset(q, math.index(ig)) > math.subset(qmax, math.index(ig))) {
                            q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - 2 * Math.PI);
                        }
                        if(math.subset(q, math.index(ig)) < math.subset(qmin, math.index(ig))) {
                            q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) + 2 * Math.PI);
                        }
                    }
                    if(math.subset(qmin, math.index(ig)) < 0 && math.subset(qmax, math.index(ig)) <= 0 && math.subset(q, math.index(ig)) > 0) {
                        q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - 2 * Math.PI);
                    }
                }
            }
            out_range = 0;
            for(let k = 0; k < N; k++) {
                if(q[k] > (qmax[k] + eps_q) || q[k] < (qmin[k] - eps_q)) {
                    out_range = 1;
                }
            }
            // end "out of range"

            if(out_range === 0 && abbr_cart < abbr_cart_min) {
                qs.push(qz._data);
                globalsuche = 0;
            } else {
                globalsuche = 1;
            }

            let abw = [];

            if(globalsuche === 1) {
                for(let lauf = 0; lauf < lges; lauf++) {
                    let q = math.subset(qstart, math.index(math.range(0, N), lauf));

                    let abbr_cart = 100;
                    let abbr_q = 100;
                    let cnt = 0;
                    let efgzw = math.ones(N, 3);
                    let abbr_cartzw = math.ones(N, 1);
                    let qzw = math.ones(N, 3);

                    [ qz, cnt, abbr_cart ] = ikpJTrans.run( F_akt, q, K_trans,r,N,cnt_max, abbr_cart_min,qmin,qmax);

                    q = qz;

                    // begin "out of range"
                    for(let ig = 0; ig < N; ig++) {
                        if(hh[ig] === 1) {
                            q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - math.fix(math.subset(q, math.index(ig)) / (2 * Math.PI + eps_q)) * 2 * Math.PI);
                            if(math.subset(qmin, math.index(ig)) >= 0 && math.subset(qmax, math.index(ig)) > 0 && math.subset(q, math.index(ig)) < 0) {
                                q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) + 2 * Math.PI);
                            }
                            if(math.subset(qmin, math.index(ig)) < 0 && math.subset(qmax, math.index(ig)) >= 0) {
                                if(math.subset(q, math.index(ig)) > math.subset(qmax, math.index(ig))) {
                                    q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - 2 * Math.PI);
                                }
                                if(math.subset(q, math.index(ig)) < math.subset(qmin, math.index(ig))) {
                                    q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) + 2 * Math.PI);
                                }
                            }
                            if(math.subset(qmin, math.index(ig)) < 0 && math.subset(qmax, math.index(ig)) <= 0 && math.subset(q, math.index(ig)) > 0) {
                                q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - 2 * Math.PI);
                            }
                        }
                    }
                    out_range = 0;
                    for(let k = 0; k < N; k++) {
                        if(q[k] > (qmax[k] + eps_q) || q[k] < (qmin[k] - eps_q)) {
                            out_range = 1;
                        }
                    }
                    // end "out of range"

                    if(out_range === 0) {
                        anz = anz + 1;
                        qloes.push(q);

                        if(abbr_cart > abbr_cart_min) {
                            genau.push(0);
                        } else {
                            genau.push(1);
                        }
                    }
                }

                if(anz > 0) {
                    let loes_anzahl = genau.reduce((a, b) => a + b, 0);
                    if(loes_anzahl >= 1) {
                        qloes_red = [];
                        let lred = 0;

                        for (let l1 = 0; l1 < (anz - 1); l1++) {
                            if (genau[l1] === 1) {
                                qloes_red.push(qloes[l1]);
                                abw.push(math.norm(math.subtract(q_alt, qloes_red[lred])));
                                lred += 1;
                            }
                        }

                        let min_loes = abw.reduce(function (lowest, next, index) {
                                return next < abw[lowest] ? index : lowest;
                            },
                            0);
                        qt = qloes_red[min_loes];
                        // console.log('qt', qt);
                        qs.push(qt._data);
                        q_alt = qt;
                        abw = [];
                    }
                    qloes_red = [];
                    abw = [];

                    if(loes_anzahl === 0) {
                        let q_letzt = qs[qs.length -1];
                        // erstmal lassen
                        // @toDo: [qz, status] = ikin_ptp_09_01_17(F_akt,q_letzt,r,mask,TIPO)
                        console.log('keine Lösung gefunden');
                    }
                }
                if(anz === 0) {
                    console.log('Abbruch wegen Ungenauigkeit');
                }

                qloes = [];
                q_alt = q;
                qt = [];
                genau = [];

                return qs;
            }

            //console.log('qs1', qs);

        }
        return qs;
    }
}

export default IkinCart;