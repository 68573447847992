let R3Planar = {
    name: 'R3_Planar',
    vmaxpo: '2.5',
    vmaxori: '250',
    amaxpo: '9',
    amaxori: '900',
    jointAmount: 3,
    joints: [
        {
            theta: '0',
            d: '0',
            a: '0.6',
            alpha: '0',
            qmin: '-90.5',
            qmax: '90.5',
            qdmax: '180',
            qddmax: '900',
            h: 1
        },
        {
            theta: '90',
            d: '0',
            a: '0.4',
            alpha: '0',
            qmin: '-175',
            qmax: '175',
            qdmax: '240',
            qddmax: '1000',
            h: 1
        },
        {
            theta: '0',
            d: '0',
            a: '0.2',
            alpha: '0',
            qmin: '-175',
            qmax: '175',
            qdmax: '300',
            qddmax: '1200',
            h: 1
        }
    ],
    images: {
        preview: 'R3Planar-preview.png',
        detail: 'R3Planar-detail.jpg'
    },
    preDefinedMask: [true, true, false, true, false, false]
};

export default R3Planar;