class Ramp {

    run(tb, tv, te, vm, bm, se, tIpo) {
        let t;
        let s = [];

        for (let i = 0; i < te / tIpo; i++) {
            t = (i+1) * tIpo;
            // Beschleunigungsphase
            if ((i+1) < tb / tIpo) {
                s[i] = Math.pow(t, 2) / 2 / tb / tv;
            }
            // Konstantfahrphase
            else if ((i+1) < tv / tIpo) {
                s[i] = t / tv - tb / 2 / tv;
            }
            // Bremsphase
            else if ((i+1) < te / tIpo) {
                s[i] = 1 - Math.pow((te - t), 2) / 2 / tb / tv;
            }
        }

        return s;
    }
}
export default Ramp;
