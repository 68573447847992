import store from '../../store';
import Fkin from './Fkin';
import Rotmat2quat from './Rotmat2quat';
import Eul2rotmat from "./Eul2rotmat";
import Rotmat2eul from "./Rotmat2eul";
import Eul2quat from "./Eul2quat";
import Synchronize from "./Synchronize";
import Quat2rotmat from "./Quat2rotmat";
import IkinCart from "./IkinCart";
import IkinCartR6 from "./IkinCartR6";

let math = require('mathjs');

class Circ {

    run(command, qstart) {
        let robot = store.translation.input.robot;
        let N = robot.jointAmount;
        let joints = robot.joints;
        let Q = [];
        let ph, pz, qz;

        let fkin = new Fkin();
        let rotmat2quat = new Rotmat2quat();
        let eul2quat = new Eul2quat();
        let rotmat2eul = new Rotmat2eul();
        let synchronize = new Synchronize();
        let quat2rotmat = new Quat2rotmat();
        let eul2rotmat = new Eul2rotmat();
        let ikin = new IkinCart();

        let T_T0 = fkin.run({...robot}, qstart);
        let T0 = T_T0[1];
        let ps = math.subset(T0, math.index([0, 1, 2], 3, N - 1)).resize([3]);
        let qs = rotmat2quat.run(math.subset(T0, math.index([0, 1, 2], [0, 1, 2], N - 1)));

        let pose = command.data.cartesianTargetPose;

        if (command.type === 'CIRCREL') {
            ph = math.add(ps, command.data.auxiliaryPoint);
            pz = math.add(ps, math.subset(pose, math.index([0, 1, 2])));
            let AW = eul2rotmat.run(math.subset(pose, math.index([3, 4, 5])));
            let AW0 = math.multiply(T0.subset(math.index([0, 1, 2], [0, 1, 2], N - 1)).resize([3, 3]), AW);
            qz = eul2quat.run(rotmat2eul.run(AW0));
        } else {
            ph = command.data.auxiliaryPoint;
            pz = math.subset(pose, math.index([0, 1, 2]));
            qz = eul2quat.run(math.multiply(math.subset(pose, math.index([3, 4, 5])), Math.PI / 180));
        }

        let xc = math.divide(math.subtract(pz, ps), math.norm(math.subtract(pz, ps)));
        let zc = math.divide(math.cross(xc, math.subtract(ph, ps)), math.norm(math.cross(xc, math.subtract(ph, ps))));
        let yc = math.cross(zc, xc);
        let TC0 = math.matrix([xc, yc, zc, ps]);
        TC0.resize([4, 4]);
        TC0 = math.subset(TC0, math.index([0, 1, 2, 3], 3), [0, 0, 0, 1])

        // removed transposing of TC0 - dont know why
        let psz_C = math.multiply((math.subset(TC0, math.index([0, 1, 2], [0, 1, 2]))), math.subtract(pz, ps));
        let psh_C = math.multiply((math.subset(TC0, math.index([0, 1, 2], [0, 1, 2]))), math.subtract(ph, ps));
        let phz_C = math.multiply((math.subset(TC0, math.index([0, 1, 2], [0, 1, 2]))), math.subtract(pz, ph));

        let rm_Cx = math.divide(math.subset(psz_C, math.index(0)), 2);
        let rm_Cy_1 = math.pow(math.subset(psh_C, math.index(0)), 2);
        let rm_Cy_2 = math.pow(math.subset(psh_C, math.index(1)), 2);
        let rm_Cy_3 = math.multiply(math.subset(psh_C, math.index(0)), math.subset(psz_C, math.index(0)));
        let rm_Cy = math.divide(math.divide(math.subtract(math.add(rm_Cy_1, rm_Cy_2), rm_Cy_3), 2), math.subset(psh_C, math.index(1)));

        let R = math.sqrt(math.add(math.pow(rm_Cx, 2), math.pow(rm_Cy, 2)));
        let phim = math.atan2(rm_Cy, rm_Cx);

        let phiz;
        if(math.add(math.pow(math.norm(psh_C), 2), math.pow(math.norm(phz_C), 2)) <= math.pow(math.norm(psz_C), 2)) {
            phiz = math.multiply(2, math.asin(math.divide(rm_Cx, R)));
        } else {
            phiz = math.subtract(2*Math.PI, math.multiply(2, math.asin(math.divide(rm_Cx, R))));
        }

        let se1 = math.multiply(R, phiz);
        qs = math.squeeze(qs);
        let se2_1 = math.multiply(math.subset(qs, math.index(0)), math.subset(qz, math.index(0)));
        let se2_2 = math.dot(math.subset(qs, math.index([1, 2, 3])), math.subset(qz, math.index([1, 2, 3])));
        let se2 = math.acos(math.add(se2_1, se2_2));
        let se = [se1, se2];

        let s_te = synchronize.run({...command}, se, [store.translation.input.vpo, store.translation.input.vori], [store.translation.input.apo, store.translation.input.aori], store.translation.input.tIpo, robot);
        let s = s_te[0];
        let te = s_te[1];

        let phi, alpha, pcx, pcy, pc;

        // Interpolation
        let F = math.zeros(3, 4, s.length);
        for(let i = 0; i < s.length; i++) {
            // Lage
            if(se[0] === 0) {
                F = math.subset(F, math.index([0, 1, 2], 3, i), math.subset(pose, math.index([0, 1, 2])));
            } else {
                phi = s[i][0] * se[0] / R;//math.divide(math.multiply(s[i][0], se[0]), R);
                alpha = (Math.PI - phi) / 2;

                if(math.abs(alpha) < 1e-4) {
                    pcx = math.multiply(2, rm_Cx);
                    pcy = math.multiply(2, rm_Cy);
                } else {
                    pc = R * math.sin(phi) / math.sin(alpha);
                    pcx = math.multiply(pc, math.cos(math.add(alpha, phim)));
                    pcy = math.multiply(pc, math.sin(math.add(alpha, phim)));
                }
                F = math.subset(F, math.index([0, 1, 2], 3, i),
                    math.add(
                        ps,
                        math.squeeze(math.multiply(
                            math.transpose(math.subset(TC0, math.index([0, 1, 2], [0, 1, 2]))),
                            math.matrix([[pcx], [pcy], [0]])
                        ))
                    )
                );
            }

            // Orientierung
            if(se[1] === 0) {
                F = math.subset(F, math.index([0, 1, 2], [0, 1, 2], i), quat2rotmat.run(qz));
            } else {
                let q = math.add(math.squeeze(math.multiply(math.divide(math.sin(math.multiply((1 - s[i][1]), se[1])), math.sin(se[1])), qs)), math.multiply(math.divide(math.sin(math.multiply(s[i][1], se[1])), math.sin(se[1])), qz));
                F = math.subset(F, math.index([0, 1, 2], [0, 1, 2], i), quat2rotmat.run(q));
            }
        }

        if(robot.name === 'R6') {
            Q = (new IkinCartR6()).run(F, qstart, robot, command.data.mask.map((el, i) => {
                if(i > 2) {
                    return !el;
                } else {
                    return el;
                }
            }), store.translation.input.tIpo);
        } else {
            Q = ikin.run(F, qstart, robot, command.data.mask.map((el, i) => {
                if(i > 2) {
                    return !el;
                } else {
                    return el;
                }
            }), store.translation.input.tIpo);
        }

        return Q;
    }
}

export default Circ;