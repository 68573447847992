let math = require('mathjs');

class Rotmat2diff {
    run(a1, a2) {
        let diff = math.matrix();
        diff = math.subset(diff, math.index([0, 1, 2], 0), math.subtract(a2.subset(math.index([0, 1, 2], 3)), a1.subset(math.index([0, 1, 2], 3))));
        /*let t1 = math.cross(a1.subset(math.index([0, 1, 2], 0)), a2.subset(math.index([0, 1, 2], 0)));
        let t2 = math.cross(a1.subset(math.index([0, 1, 2], 1)), a2.subset(math.index([0, 1, 2], 1)));
        let t3 = math.cross(a1.subset(math.index([0, 1, 2], 2)), a2.subset(math.index([0, 1, 2], 2)));
        let t4 = math.add(t1, t2);
        let t5 = math.add(t4, t3);
        // TODO: check out, why?!
        t5 = math.transpose(t5);
        diff = diff.subset(math.index([3, 4, 5], 0), math.multiply(0.5, t5));*/

        let tmp = math.multiply(math.subset(a2, math.index([0, 1, 2], [0, 1, 2])), math.transpose(math.subset(a1, math.index([0, 1, 2], [0, 1, 2]))));
        diff = math.subset(diff, math.index([3, 4, 5], 0), math.multiply(0.5, math.transpose(math.matrix([
            tmp.get([2, 1])-tmp.get([1,2]),
            tmp.get([0, 2])-tmp.get([2, 0]),
            tmp.get([1, 0])-tmp.get([0, 1])
        ]))));
        //console.log(diff);
        return diff;
    }
}

export default Rotmat2diff;