class Sino {

    run(tb, tv, te, vm, bm, se, tIpo) {
        let t;
        let s = [];

        for (let i = 0; i < te / tIpo; i++) {
            t = i * tIpo;
            // Beschleunigungsphase
            if (i <= tb / tIpo) {
                s[i] = 2 / tb / tv * (0.25 * Math.pow(t, 2) + Math.pow(tb, 2) / 8 / Math.pow(Math.PI, 2) * (Math.cos(2 * Math.PI * t / tb) - 1));
            }
            // Konstantfahrphase
            else if (i <= tv / tIpo) {
                s[i] = (t - 0.5 * tb) / tv;
            }
            // Bremsphase
            else if (i <= te / tIpo) {
                s[i] = (te * (t + tb) - 0.5 * (Math.pow(t, 2) + Math.pow(te, 2) + 2 * Math.pow(tb, 2)) + Math.pow(tb, 2) / 4 / Math.pow(Math.PI, 2) * (1 - Math.cos(2 * Math.PI * (t - tv) / tb))) / tb / tv;
            }
        }

        return s;
    }
}

export default Sino;