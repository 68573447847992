let math = require('mathjs');

class Startposen {
    run(N, nw, qmin, qmax) {

        let dqabst = [];
        for(let n = 0; n < N; n++) {
            dqabst.push((qmax[n] - qmin[n])/(nw + 1));
        }

        let qzw = math.zeros(N, nw);

        for(let i = 0; i < N; i++) {
            for(let l = 0; l < nw; l++) {
                qzw = math.subset(qzw, math.index(i, l), qmin[i] + (l+1)*dqabst[i]);
            }
        }

        let lpos = math.pow(nw, N);

        let qzu = [];
        let qers = [];
        let lb = math.zeros(N);
        let anzpack;

        let qst_pos = math.zeros(N, lpos);

        for(let i1 = 0; i1 < N; i1++) {
            lb = math.subset(lb, math.index(i1), math.pow(nw, N-(i1 + 1)));

            qzu = [];
            for(let i2 = 0; i2 < nw; i2++) {
                for(let i3 = 0; i3 < math.subset(lb, math.index(i1)); i3++) {
                    qzu.push(math.subset(qzw, math.index(i1, i2)));
                }
            }
            anzpack = lpos / (nw * math.subset(lb, math.index(i1)));

            for(let i4 = 0; i4 < anzpack; i4++) {
                qers = qers.concat(qzu);
            }
            qst_pos = math.subset(qst_pos, math.index(i1, [...Array(qst_pos.size()[1]).keys()]), qers);
            qers = [];
        }

        return [
            qst_pos, lpos
        ]
    }
}

export default Startposen;