import * as math from 'mathjs';

class Quatmult {
    run(q1, q2) {
        let q = math.matrix();
        q = math.subset(q, math.index(0), math.multiply(math.subset(q1, math.index(0)), math.subset(q2, math.index(0))) - math.dot(math.subset(q1, math.index([1, 2, 3])), math.subset(q2, math.index([1, 2, 3]))));
        q = math.subset(q, math.index([1, 2, 3]), math.add(math.add(math.multiply(math.subset(q1, math.index(0)), math.subset(q2, math.index([1, 2, 3]))), math.multiply(math.subset(q2, math.index(0)), math.subset(q1, math.index([1, 2, 3])))), math.cross(math.subset(q1, math.index([1, 2, 3])), math.subset(q2, math.index([1, 2, 3])))));

        return q;
    }
}

export default Quatmult;