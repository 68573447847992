import {evaluate} from 'mathjs';

class Quat2rotmat {
    run(quat) {
        let a = evaluate('quat[1]', {quat});
        let b = evaluate('quat[2]', {quat});
        let c = evaluate('quat[3]', {quat});
        let d = evaluate('quat[4]', {quat});
        
        let x_akt = evaluate('squeeze([1-2*(c^2+d^2); 2*(b*c+a*d); 2*(b*d-a*c)])', {a, b, c, d});
        let y_akt = evaluate('squeeze([2*(b*c-a*d); 1-2*(d^2+b^2); 2*(c*d+a*b)])', {a, b, c, d});
        let z_akt = evaluate('squeeze([2*(b*d+a*c);2*(c*d-a*b);1-2*(b^2+c^2)])', {a, b, c, d});
        
        x_akt = evaluate('x_akt/norm(x_akt)', {x_akt});
        y_akt = evaluate('y_akt/norm(y_akt)', {y_akt});
        z_akt = evaluate('z_akt/norm(z_akt)', {z_akt});

        return evaluate('transpose([x_akt, y_akt, z_akt])', {x_akt, y_akt, z_akt});
    }
}

export default Quat2rotmat;