import store from '../../store';
import * as math from 'mathjs';
import Matrix from 'ml-matrix';
import Fkin from "./Fkin";
import Rotmat2diff from "./Rotmat2diff";
import Jacobimatrix from "./Jacobimatrix";

class Ikin {
    run(F, q, robot, mask, tIpo) {
        //console.log('mask', mask);

        let fkin = new Fkin();
        let rotmat2diff = new Rotmat2diff();
        let jacobimatrix = new Jacobimatrix();

        let N = robot.jointAmount;

        let qs = [];

        let abbr, cnt, qi, T_T0, T, T0, e, J, Jpi, dq, T0t;

        q = math.transpose(math.matrix(q));

        for(let i = 0; i < F.size()[2]; i++) {
            abbr = 1;
            cnt = 0;
            qi = q;

            while(abbr > 5e-6) {
                T_T0 = fkin.run({...robot}, q);
                T = T_T0[0];
                T0 = T_T0[1];
                T0t = math.squeeze(math.subset(T0, math.index(math.range(0, T0.size()[0]), math.range(0, T0.size()[1]), (N-1))));
                e = rotmat2diff.run(T0t, math.squeeze(math.subset(F, math.index(math.range(0, F.size()[0]), math.range(0, F.size()[1]), i))));
                J = jacobimatrix.run(robot, T0);
                let JM = new Matrix(J._data);
                Jpi = math.matrix(JM.pseudoInverse());

                /*if(i ==29) {
                    console.log('i', i);
                    console.log('q', q);
                    console.log('T0', T0);
                    console.log('J', J);
                    console.log('Jpi', Jpi);
                }*/

                if(N < 6) {
                    dq = math.multiply(Jpi, math.squeeze(e._data.map((el, ix) => {
                        if (mask[ix] === true) {
                            return el;
                        } else {
                            return 0;
                        }
                    })));
                } else {
                    dq = math.multiply(Jpi, e);
                }

                dq = math.squeeze(dq);
                q = math.add(q, dq);

                for(let j = 0; j < N; j++) {
                    if(math.subset(q, math.index(j)) < robot.joints[j].qmin) {
                        q = math.subset(q, math.index(j), robot.joints[j].qmin);
                    }
                    if(math.subset(q, math.index(j)) > robot.joints[j].qmax) {
                        q = math.subset(q, math.index(j), robot.joints[j].qmax);
                    }
                }

                abbr = math.norm(dq);
                cnt = cnt + 1;
                //console.log(abbr, cnt);
                if(cnt > 15000) {
                    store.translation.status = 'Error';
                    store.translation.errorMessage = 'Out of workspace.';
                    qs = [];
                    return;
                }
            }

            for(let k = 0; k < N; k++) {
                if(math.subset(q, math.index(k)) > robot.joints[k].qmax || math.subset(q, math.index(k)) < robot.joints[k].qmin) {
                    store.translation.status = 'Error';
                    store.translation.errorMessage = 'Out of workspace. (2)';
                    qs = [];
                    return;
                }
            }

            qs = qs.concat([q._data]);
        }
        return qs;
    }
}

export default Ikin;