class CommandFormAuxiliaryPointAndTargetPoseModel {
    velocity = 1;

    mask = [false, false, false, false, false, false];
    auxiliaryPoint = ['', '', ''];
    cartesianTargetPose = ['', '', '', '', '', ''];

    constructor(robot) {
        if(robot.data.preDefinedMask) {
            this.mask = robot.data.preDefinedMask;
        }
    }

    toString(robotJointAmount) {
        let out = '( ';
        let val = null;
        for (let i = 0; i < this.auxiliaryPoint.length; i++) {
            val = this.auxiliaryPoint[i];
            out += (val !== '' ? val : '-') + (i === 2 ? ',' : '') + ' ';
        }
        for (let i = 0; i < this.cartesianTargetPose.length; i++) {
            val = this.cartesianTargetPose[i];
            out += (val !== '' ? val : '-') + ' ';
        }
        out += ') ';
        out += this.velocity === 1 ? 'RAMP' : 'SINO';
        return out;
    }
}

export default CommandFormAuxiliaryPointAndTargetPoseModel;