import * as math from 'mathjs';

class Rotmat2quat {
    run(A) {
        A = math.squeeze(A);
        let qt1 = 0.5 * math.sqrt(math.subset(A, math.index(0, 0)) + math.subset(A, math.index(1, 1)) + math.subset(A, math.index(2, 2)) + 1);
        let qt2 = 0.5 * this.csign(math.subset(A, math.index(2, 1)) - math.subset(A, math.index(1, 2))) * math.sqrt(math.subset(A, math.index(0, 0)) - math.subset(A, math.index(1, 1)) - math.subset(A, math.index(2, 2)) + 1);
        let qt3 = 0.5 * this.csign(math.subset(A, math.index(0, 2)) - math.subset(A, math.index(2, 0))) * math.sqrt(math.subset(A, math.index(1, 1)) - math.subset(A, math.index(2, 2)) - math.subset(A, math.index(0, 0)) + 1);
        let qt4 = 0.5 * this.csign(math.subset(A, math.index(1, 0)) - math.subset(A, math.index(0, 1))) * math.sqrt(math.subset(A, math.index(2, 2)) - math.subset(A, math.index(0, 0)) - math.subset(A, math.index(1, 1)) + 1);

        return math.matrix([[qt1], [qt2], [qt3], [qt4]]);
    }

    csign(a) {
        return a < -1e-12 ? -1 : 1;
    }
}

export default Rotmat2quat;