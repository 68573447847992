import React, {Component} from 'react';
import {Row, Col, FormGroup, ControlLabel, DropdownButton, MenuItem, InputGroup, FormControl} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../../store.js';

class CommandFormSpeedPtp extends Component {

    jointValueChange(e) {
        let val = e.target.value;
        if (/^-?\d*\.?\d*$/.test(val)) {
            let intVal = parseInt(val, 10)
            if((intVal >= 0 && intVal <= 100) || (isNaN(intVal) && val.length === 0)) {
                this.props.command.data.jointValue[e.target.getAttribute('dataindex')] = val;
                store.commandValueChanged();
            }
        }
    }

    render() {
        let command = this.props.command;
        const { jointValueChange } = this;

        return (
            <Row>
                {[...Array(store.robot.data.jointAmount).keys()].map(function (x) {
                    return (
                        <Col xs={4} key={x}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroup.Addon>Joint {(x + 1)}</InputGroup.Addon>
                                    <FormControl type="text" dataindex={x} onChange={jointValueChange}
                                                 value={command.data.jointValue[x]}/>
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    )
                })}
            </Row>
        )
    }
}
export default easyComp(CommandFormSpeedPtp);
