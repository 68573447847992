let math = require('mathjs');

class Eul2rotmat {
    run(eul) {
        console.log('eul', eul);
        for (let i = 0; i < 3; i++) {
            eul[i] = eul[i] * Math.PI / 180;
        }
        let sA = Math.sin(eul[0]);
        let cA = Math.cos(eul[0]);
        let sB = Math.sin(eul[1]);
        let cB = Math.cos(eul[1]);
        let sC = Math.sin(eul[2]);
        let cC = Math.cos(eul[2]);

        return math.matrix([
            [cA * cB, -sA * cC + cA * sB * sC, sA * sC + cA * sB * cC],
            [sA * cB, cA * cC + sA * sB * sC, -cA * sC + sA * sB * cC],
            [-sB, cB * sC, cB * cC]
        ])
    }
}

export default Eul2rotmat;