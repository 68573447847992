import * as math from "mathjs";

// TODO: neu bauen - done?!

class Rotmat2eul {
    run(rotmat) {

        // die vierte Zeile von rotmat (alles Nullen), wird weggelassen
        rotmat = math.subset(rotmat, math.index(math.range(0, 3), math.range(0, rotmat.size()[1])));

        // bei einer Zahl, die weniger als delta_eps zur n�chstewn ganzen Zahl liegt, wird die ganze Zahl genommen
        let delta_eps = 0.0002;
        for (let i1 = 0; i1 < 3; i1++) {
            for (let i2 = 0; i2 < 2; i2++) {
                let zw1 = math.round(math.subset(rotmat, math.index(i1, i2)));
                if (math.abs(zw1 - math.subset(rotmat, math.index(i1, i2))) < delta_eps) {
                    math.subset(rotmat, math.index(i1, i2), zw1);
                }
            }
        }

        let eul = math.zeros([3, 1]);

        // Normierung, um numerische Schwierigkeiten bei den inversen trigonometrischen Funktionen zu vermeiden
        rotmat = math.subset(rotmat, math.index([0, 1, 2], 0), math.divide(math.subset(rotmat, math.index([0, 1, 2], 0)), math.norm(math.squeeze(math.subset(rotmat, math.index([0, 1, 2], 0))))));
        rotmat = math.subset(rotmat, math.index([0, 1, 2], 1), math.divide(math.subset(rotmat, math.index([0, 1, 2], 1)), math.norm(math.squeeze(math.subset(rotmat, math.index([0, 1, 2], 1))))));
        // Hier wird z als x kreuz y angegeben
        rotmat = math.subset(rotmat, math.index([0, 1, 2], 2), math.transpose(math.cross(math.subset(rotmat, math.index([0, 1, 2], 0)), math.subset(rotmat, math.index([0, 1, 2], 1)))));
        //rotmat = math.subset(rotmat, math.index([0, 1, 2], 2), math.divide(math.subset(rotmat, math.index([0, 1, 2], 2)), math.norm(math.squeeze(math.subset(rotmat, math.index([0, 1, 2], 2))))));

        eul = math.subset(eul, math.index(1, 0), math.asin(math.multiply(-1, math.subset(rotmat, math.index(2, 0)))));

        //console.log('eul, rotmat', eul, rotmat);

        // B = -90
        if(math.abs(math.subset(eul, math.index(1, 0)) + Math.PI / 2) < 0.00001) {
            let sumAC = math.atan2(math.multiply(-1, math.subset(rotmat, math.index(0, 1))), math.subset(rotmat, math.index(1, 1)));
            eul = math.subset(eul, math.index(0, 0), 0);
            eul = math.subset(eul, math.index(2, 0), sumAC - math.subset(eul, math.index(0, 0)));

            if(math.subset(eul, math.index(2,0)) < Math.PI * -1) {
                eul = math.subset(eul, math.index(2, 0), math.add(math.subset(eul, math.index(2, 0)), Math.PI*2));
            } else if (math.subset(eul, math.index(2,0)) > Math.PI) {
                eul = math.subset(eul, math.index(2, 0), math.subtract(math.subset(eul, math.index(2, 0)), Math.PI*2));
            }
        }
        // B = 90
        else if(math.abs(math.subset(eul, math.index(1, 0)) - Math.PI / 2) < 0.00001) {
            let diffAC = math.atan2(math.subset(rotmat, math.index(0, 1)), math.subset(rotmat, math.index(0, 2)));
            eul = math.subset(eul, math.index(0, 0), Math.PI / 2);
            eul = math.subset(eul, math.index(2, 0), math.add(diffAC, math.subset(eul, math.index(0, 0))));
        }
        // B beliebig
        else {
            eul = math.subset(eul, math.index(0, 0), math.atan2(math.subset(rotmat, math.index(1, 0)), math.subset(rotmat, math.index(0, 0))));
            eul = math.subset(eul, math.index(2, 0), math.atan2(math.subset(rotmat, math.index(2, 1)), math.subset(rotmat, math.index(2, 2))));
        }

        // Bei Betrag eines Euler-Winkels von 180� wird das positive Vorzeichen bevorzugt
        for(let i = 0; i < 3; i++) {
            if(math.abs(math.subtract(math.abs(math.subset(eul, math.index(i, 0))), Math.PI)) < 0.00001) {
                eul = math.subset(eul, math.index(i, 0), Math.PI);
            }
        }

        return eul;

    }
}

export default Rotmat2eul;
