class CommandFormSpeedPtpModel {

    jointValue = (new Array(10)).fill('100');

    toString(jointAmount) {
        let out = '( ';
        for (let i = 0; i < jointAmount; i++) {
            out += this.jointValue[i] + ' ';
        }
        out += ')';
        return out
    }
}

export default CommandFormSpeedPtpModel;